<template>
    <div v-if="!areProductsHidden" class="products" v-bind:class="{ disabled_faded: new Date().getDay() == 0 }" id="products">
        <div v-show="!isFiltering">
            <div v-for="(category, index) in availableCategories" :key="index" class="category" v-show="hasProducts(category) && isCategoryVisible(category)">
                <div class="category-title-wrapper">
                    <h2 class="category-title py-sm-2 dreamorp-reg">{{ category.retail_title ?? category.name }}</h2>
                </div>

                <div class="row">
                    <product
                        v-for="product in categorizedProducts(category.slug)"
                        :product="product"
                        :key="product.id"
                        v-show="isVisible(product)"
                        @quickview="handleQuickview"
                    ></product>
                </div>

                <div v-for="subcategory in category.subcategories" :key="subcategory.id" v-show="hasProducts(subcategory) && isCategoryVisible(subcategory)">
                    <h3 class="subcategory-title py-sm-2 dreamorp-reg">{{ subcategory.name }}</h3>

                    <div class="row">
                        <product
                            v-for="product in categorizedProducts(subcategory.slug)"
                            :product="product"
                            :key="product.id"
                            v-show="isVisible(product)"
                            @quickview="handleQuickview"
                        ></product>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="isFiltering">
            <div class="category-title-wrapper">
                <h2
                    v-show="selectedFilter && !brandFilters.length"
                    class="category-title d-none d-sm-block category-title-large dreamorp-reg py-sm-2"
                >
                    {{ selectedFilter.large_screen }}
                </h2>
                <h2
                    v-show="selectedFilter && !brandFilters.length"
                    class="category-title d-sm-none dreamorp-reg py-sm-2"
                >
                    {{ selectedFilter.small_screen }}
                </h2>
            </div>

            <div class="row">
                <product
                    v-for="product in products"
                    :product="product"
                    :key="product.id"
                    v-show="isVisible(product)"
                    @quickview="handleQuickview"
                ></product>

                <h5 class="text-center" v-show="productsVisible === 0" style="width: 100%;">
                    0 matches found <span v-if="searchTerm">for "{{ this.searchTerm }}"</span>
                </h5>
            </div>
        </div>

        <product-quickview
            :product="quickviewProduct"
            :show="showQuickview"
            @close="showQuickview = false"
        ></product-quickview>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import Product from '@/components/Product.vue';
    import ProductQuickview from '@/components/ProductQuickview.vue';
    import { trackViewItem } from '../dataLayer';

    export default {
        components: {
            Product,
            ProductQuickview,
        },

        data () {
            return {
                quickviewProduct: null,
                showQuickview: false,

                staticCategories: [
                    { id: null, name: 'Specials', slug: 'special', isSection: true, subcategories: []},
                    { id: null, name: 'New Items', slug: 'new', isSection: true, subcategories: []},
                ]
            }
        },

        computed: {
            ...mapGetters(['products']),

            ...mapState({
                searchTerm: state => state.products.searchTerm,
                foodFilters: state => state.products.foodFilters,
                promoFilters: state => state.products.promoFilters,
                brandFilters: state => state.products.brandFilters,
                categories: state => state.categories,
                areProductsHidden: state => state.products.areProductsHidden,
            }),

            isFiltering () {
                // return this.searchTerm.length || this.brandFilters.length;
                return this.searchTerm.length || this.foodFilters.length || this.promoFilters.length || this.brandFilters.length;
            },

            selectedFilter () {
                // If searching, show the searching header
                if (this.searchTerm.length) return 'Search Results';

                // If the selected filter is a subcategory, return the parent name and subcategory name
                // Otherwise return the parent
                let parent_category = null;
                let subcategory = null;

                parent_category = this.topLevelCategories.find(c => {
                    return this.foodFilters.includes(c.slug) || this.promoFilters.includes(c.slug);
                });

                if(!parent_category) {

                    this.categories.filter(i => i.subcategories.length > 0).some((c) => {
                        c.subcategories.find(s => {
                            if(this.foodFilters.includes(s.slug) || this.promoFilters.includes(s.slug)) {
                                parent_category = c;
                                subcategory = s;
                            }
                        });
                    });
                }

                return {
                    'large_screen': parent_category ? subcategory ? (parent_category.retail_title ?? parent_category.name) + ' - ' + (subcategory.retail_title ?? subcategory.name) : (parent_category.retail_title ?? parent_category.name) : '',
                    'small_screen': parent_category ? subcategory ? (subcategory.retail_title ?? subcategory.name) : (parent_category.retail_title ?? parent_category.name) : '',
                };
            },

            selectedCategory () {
                if (this.foodFilters.length === 0) return null;

                return this.allCategories.find(c => c.slug === this.foodFilters[0]);
            },

            productsVisible () {
                let visible = 0;

                this.products.forEach(p => {
                    if (this.isVisible(p)) {
                        visible++;
                    }
                })

                return visible;
            },

            allCategories () {
                let categories = this.topLevelCategories.reduce((carry, value) => {
                    let subcategories = value.subcategories.reduce((subCarry, subValue) => subCarry.concat(subValue), []);

                    return carry.concat(value, subcategories);
                }, []);

                return categories;
            },

            topLevelCategories() {
                let staticCategories = this.staticCategories;
                let dynamicCategories = JSON.parse(JSON.stringify(this.categories));

                return staticCategories.concat(dynamicCategories);
            },

            availableCategories () {
                let categories = [];

                this.topLevelCategories.filter(c => c.isSection).forEach(c => {
                    const match = this.products.find(p => {
                        if (c.slug === 'special') {
                            return p.special === 1;
                        } else if (c.slug === 'new') {
                            return p.new === true;
                        } else if (c.slug === 'organic') {
                            return p.organic === true;
                        }

                        const submatch = c.subcategories.find(sub => p.category_id === sub.id);

                        return (submatch || p.category_id === c.id) && p.active === 1;
                    });

                    if (match) {
                        categories.push(c);
                    }
                });

                return categories;
            }
        },

        methods: {
            hasProducts (category) {
                if (category.slug === 'special') {
                    return this.products.find(p => p.special === 1);
                } else if (category.slug === 'new') {
                    return this.products.find(p => p.new === true);
                } else if (category.slug === 'organic') {
                    return this.products.find(p => p.organic === true);
                }

                let ids = [category.id];

                if (category.subcategories) {
                    ids = ids.concat(category.subcategories.reduce((c, v) => c.concat([v.id]), []));
                }

                return this.products.find(p => p.active && ids.includes(p.category_id));
            },

            isCategoryVisible (category) {
                if (this.foodFilters.length === 0 && this.promoFilters.length === 0) {
                    return true;
                }

                let slugs = [category.slug];

                if (category.subcategories) {
                    slugs = slugs.concat(category.subcategories.reduce((c, v) => c.concat([v.slug]), []));
                } else {
                    let parentCategory = this.categories.find(c => c.subcategories.find(c2 => c2.slug === category.slug));

                    if (parentCategory) {
                        slugs = slugs.concat(parentCategory.slug);
                    }
                }

                return slugs.includes(this.foodFilters[0]) || slugs.includes(this.promoFilters[0]);
            },

            categorizedProducts (category) {
                let category_ids = [];
                let parent_category = this.allCategories.find(c => c.slug === category);

                category_ids.push(parent_category.id);

                switch (category) {
                    case 'special':
                        return this.products.filter(p => p.special === 1);

                    case 'new':
                        return this.products.filter(p => p.new === true);

                    case 'organic':
                        return this.products.filter(p => p.organic === true);

                    default:
                        return this.products.filter(p => (category_ids.includes(p.category_id)));
                }
            },

            isVisible (product) {
                let search = this.searchTerm === ''
                    || product.title.toUpperCase().indexOf(this.searchTerm.toUpperCase()) !== -1
                    || (product.description && product.description.toUpperCase().indexOf(this.searchTerm.toUpperCase()) !== -1);

                // Find parent or subcategory...
                let parent_category_slug = '';
                let subcategory_slug = '';

                let parent_category = this.categories.find(c => c.id === product.category_id);
                if (parent_category) parent_category_slug = parent_category.slug;
                else {
                    this.categories.filter(i => i.subcategories.length > 0).some((c) => {
                        c.subcategories.find(s => {
                            if(s.id === product.category_id) {
                                parent_category_slug = c.slug;
                                subcategory_slug = s.slug;
                            }
                        });
                    });
                }

                let food = this.foodFilters.length === 0 ||
                    this.foodFilters.includes(parent_category_slug) ||
                    this.foodFilters.includes(subcategory_slug) ||
                    (this.foodFilters.includes('fresh-unique-local') && product.locally_grown);
                
                let promo = this.promoFilters.length === 0
                    || (this.promoFilters.includes('special') && product.special)
                    || (this.promoFilters.includes('new') && product.new)
                    || (this.promoFilters.includes('organic') && product.organic)
                    || (this.promoFilters.includes('locally_grown') && product.locally_grown)
                    || (this.promoFilters.includes('pinned') && product.pinned);

                let brand = this.brandFilters.length === 0
                    || (product.brand && this.brandFilters.includes(product.brand.id));

                return search && food && promo && brand;
            },

            handleQuickview (product) {
                this.quickviewProduct = product;
                this.showQuickview = true;

                this.$router.push({ name: 'quickview', params: { slug: product.slug }});

                trackViewItem(product);
            },
        },
    }
</script>

<style>

.products {
    margin-bottom: 120px;
    min-height: 100vh;
}

.products .card-body {
    padding: 0.5rem;
    height: 100%;
}

</style>

<style scoped>

.category-title-wrapper {
    position: sticky;
    top: 225px;
    z-index: 100;
    background-color: white;
    margin: 0px -15px;
    padding: 0px 15px;
}

body.stickied .category-title-wrapper {
    top: 192.5px;
}

.category-title {
    text-align: left;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #85C71E;
}

.subcategory-title {
    text-align: left;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

@media (min-width: 768px) {
    .category-title {
        font-size: 2.375rem;
        text-align: left;
    }

    .subcategory-title {
        font-size: 1.25rem;
        text-align: left;
    }
}

@media (max-width: 1200px) {
    .category-title-wrapper {
        top: 177.5px;
    }
    
    body.stickied .category-title-wrapper {
        top: 185px;
    }
}

@media (max-width: 768px) {
    .category-title-wrapper {
        top: 185px;
        padding-top: 16px;
    }
    body.stickied .category-title-wrapper {
        top: 185px;
        padding-top: 8px;
    }
}
</style>
