<template>
    <div class="footer-summary custom-shadow">
        <div class="order-total text-nowrap">
            <span class="d-none d-md-inline">Order Total: </span> 
            <span class="font-weight-bold">${{ subtotal.toFixed(2) }}</span>
        </div>

        <button class="btn btn-primary ml-4 rounded-pill" :disabled="subtotal == 0" @click="handleToCheckout">
            CHECKOUT
        </button>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters(['subtotal']),
        },

        methods: {
            handleToCheckout () {
                this.$router.push('/cart');
            }
        }
    }
</script>

<style scoped>
    .footer-summary {
        display: none;
        padding: 0.5rem 1rem;
        position: fixed;
        bottom: 20px;
        right: 20px;
        color: white;
        background: #091601;
        z-index: 15;
        border: 2px solid #85C71E;
        border-radius: 1000px;
        align-items: center;
        justify-content: space-between;
    }

    .order-total {
        margin-left: auto;
        font-size: 1.125rem;
        font-weight: 600;
    }

    .btn {
        padding: 6px 12px;
    }

    @media (min-width: 768px) {
    }

    @media (min-width: 992px) {
        .footer-summary {
            justify-content: initial;
            display: flex;
            padding: 1rem;
        }
    }
</style>